import { HttpMethod } from '@dh/keycloak-auth';

import { AppService } from '../../App.service';
import { PrivacyPoliciesData } from './privacy-policies.type';
import { SuccessResponse } from '../../utils/interfaces';
import { consumerIdApi } from '../../config';

export default class PrivacyPoliciesService extends AppService {
    private readonly controllerPath = '/privacy-policy';

    /**
     * Method to get the status of the privacy policies.
     * @param token Token sent by NTTData
     * @returns Promise<PrivacyPoliciesResponse>
     */
    public async getStatus(
        requestId: string,
        token: string,
    ): Promise<PrivacyPoliciesData> {
        return this.client
            .fetch<PrivacyPoliciesData>({
                path: `${this.controllerPath}/status/${requestId}`,
                method: HttpMethod.GET,
                additionalParams: {
                    headers: { Authorization: token, 'Tracking-Id': requestId },
                },
            })
            .then((response) => response.data)
            .catch((error) => {
                throw this.handleError(error);
            });
    }

    /**
     * Method to send decision in privacy policies and use information.
     * @param {PrivacyPoliciesData}
     * @param token Token sent by NTTData
     * @returns Promise<PrivacyPoliciesResponse>
     */
    public async sendDecision(
        params: PrivacyPoliciesData,
        token: string,
    ): Promise<SuccessResponse> {
        return this.client
            .fetch<SuccessResponse>({
                path: `${this.controllerPath}/confirmation`,
                method: HttpMethod.POST,
                body: params,
                additionalParams: {
                    headers: {
                        Authorization: token,
                        'Tracking-Id': params.requestId,
                        'Consumer-Id': consumerIdApi,
                    },
                },
            })
            .then((response) => response.data)
            .catch((error) => {
                throw this.handleError(error);
            });
    }
}
