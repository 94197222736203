import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom';
import {
    BronsonAccordion,
    BronsonAccordionRow,
    BronsonButton,
    BronsonToastNotification,
    BronsonToastProps,
} from '@dh/bronson-react';
import { useEffect, useMemo, useState } from 'react';

import {
    decodeToken,
    validateExpirationDateToken,
} from '../../utils/token.util';
import {
    DecisionForm,
    PrivacyPoliciesData,
    PayloadTokenData,
} from './privacy-policies.type';
import { getContent } from './privacy-policies.content';
import { Hero } from '../../components/Hero';
import { Modal } from '../../utils/interfaces';
import { modalComponent } from '../../utils/general.utils';
import { privacyPoliciesLabels } from './privacy-policies.labels';
import PrivacyPoliciesService from './privacy-policies.services';
import { toastInitError } from '../../utils/constants';

/**
 * A custom hook that builds on useLocation to parse the query string
 */
function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Privacy policies page
 * @returns {React.FC}
 */
const PrivacyPolicies: React.FC = () => {
    /**
     * Keycloak instance.
     */
    const [keycloak] = useKeycloak();
    const query = useQuery();
    const token = query.get('token') as string | '';
    const [signed, setSigned] = useState<boolean>(false);
    const [notificationStatus, setNotificationStatus] =
        useState<BronsonToastProps>(toastInitError);
    const [buttonText, setButtonText] = useState(
        privacyPoliciesLabels.button.accept,
    );
    const payload: PayloadTokenData | null = decodeToken(token);
    const [expired, setExpired] = useState<boolean>(false);
    const [enableAcceptButton, setEnableAcceptButton] =
        useState<boolean>(false);
    const [modalData, setModalData] = useState<Modal>({
        type: 'processing',
        id: '',
        isHidden: true,
        isLoading: false,
        footerCloseButton: false,
    } as Modal);
    const [dataRequest, setDataRequest] = useState<PrivacyPoliciesData>(
        {} as PrivacyPoliciesData,
    );
    const [data, setData] = useState<DecisionForm>({
        personalData: {
            id: 'personalData',
            group: 'personal-data-processing',
            isDisabled: false,
        },
        privacyPolicies: {
            id: 'privacyPolicies',
            group: 'privacy-policies',
            isDisabled: false,
        },
    });
    const [rows, setRows] = useState<Array<JSX.Element>>();

    useEffect(() => {
        if (payload && dataRequest && dataRequest.responseDate) {
            const isSigned = !!dataRequest.responseDate;
            setSigned(isSigned);
            if (isSigned) {
                setNotificationStatus((notification) => ({
                    ...notification,
                    isVisible: true,
                    type: 'success',
                    content: privacyPoliciesLabels.notification.signed,
                }));
                let newFormData: DecisionForm;
                setData((dataPreview) => {
                    newFormData = {
                        personalData: {
                            ...dataPreview.personalData,
                            value: dataRequest.useOfInformationAllowed.toString(),
                        },
                        privacyPolicies: {
                            ...dataPreview.privacyPolicies,
                            value: dataRequest.privacyPolicyAccepted.toString(),
                        },
                    };
                    if (
                        newFormData.personalData.value &&
                        newFormData.privacyPolicies.value
                    ) {
                        setEnableAcceptButton(true);
                    }
                    return newFormData;
                });
            }
        }
    }, [dataRequest, expired]);

    useEffect(() => {
        if (payload) {
            const validExpiration = validateExpirationDateToken(
                payload.expirationDate,
            );
            if (!validExpiration) {
                setButtonText(privacyPoliciesLabels.button.expired);
                setExpired(true);
                setNotificationStatus((notification) => ({
                    ...notification,
                    isVisible: true,
                    type: 'warning',
                    content: privacyPoliciesLabels.notification.expired,
                }));
                setData((dataPreview) => ({
                    personalData: {
                        ...dataPreview.personalData,
                        isDisabled: true,
                    },
                    privacyPolicies: {
                        ...dataPreview.privacyPolicies,
                        isDisabled: true,
                    },
                }));
            } else if (dataRequest && !dataRequest.petitionId) {
                const service = new PrivacyPoliciesService(keycloak);
                service
                    .getStatus(payload.requestId, token)
                    .then((response) => {
                        setDataRequest(response);
                    })
                    .catch((error) => {
                        setEnableAcceptButton(false);
                        setButtonText(privacyPoliciesLabels.button.accept);
                        setNotificationStatus((notification) => ({
                            ...notification,
                            isVisible: true,
                            type: 'error',
                            content:
                                privacyPoliciesLabels.error.validate.replace(
                                    '[error]',
                                    error,
                                ),
                        }));
                    });
            }
        } else {
            window.location.href = '#/login';
        }
    }, []);

    /**
     * Method to sign privacy policies.
     */
    const signPrivacyPolicies = async () => {
        const service = new PrivacyPoliciesService(keycloak);
        setModalData((modal) => ({
            ...modal,
            type: 'processing',
            isHidden: false,
            isLoading: true,
        }));
        service
            .sendDecision(
                {
                    ...dataRequest,
                    privacyPolicyAccepted:
                        data.privacyPolicies.value === 'true',
                    useOfInformationAllowed: data.personalData.value === 'true',
                },
                token,
            )
            .then(() => {
                setModalData((modal) => ({
                    ...modal,
                    isHidden: true,
                    isLoading: false,
                }));
                setNotificationStatus((notification) => ({
                    ...notification,
                    isVisible: true,
                    type: 'success',
                    content: privacyPoliciesLabels.notification.accept,
                }));
                setSigned(true);
            })
            .catch((error) => {
                setModalData((modal) => ({
                    ...modal,
                    isHidden: true,
                    isLoading: false,
                }));
                setNotificationStatus((notification) => ({
                    ...notification,
                    isVisible: true,
                    type: 'error',
                    content: privacyPoliciesLabels.error.accept.replace(
                        '[error]',
                        error,
                    ),
                }));
            });
    };

    /**
     * Method to change data in form.
     */
    const onChangeData = (key: string, value: string) => {
        const keys = key.split('.');
        let newFormData: DecisionForm;
        setData((dataPreview) => {
            newFormData = {
                ...dataPreview,
                [keys[0]]: {
                    ...dataPreview[keys[0]],
                    value,
                },
            };
            if (
                newFormData.personalData.value &&
                newFormData.privacyPolicies.value
            ) {
                setEnableAcceptButton(true);
            }
            return newFormData;
        });
    };
    useEffect(() => {
        setRows([
            <BronsonAccordionRow
                isOpen={true}
                keyIdentifier={0}
                content={getContent(data, onChangeData)}
                key={0}
                title={<p>{privacyPoliciesLabels.accordionTitle}</p>}
            />,
        ]);
    }, [data]);

    return (
        <div className='o-page-wrap'>
            {modalComponent(modalData, setModalData)}
            <BronsonToastNotification
                {...notificationStatus}
                setVisibility={() =>
                    setNotificationStatus((notification) => ({
                        ...notification,
                        isVisible: false,
                    }))
                }
            />
            <Hero
                title={privacyPoliciesLabels.hero.title}
                description={privacyPoliciesLabels.hero.subtitle.toUpperCase()}
            />
            <div style={{ textAlign: 'justify' }}>
                <div className='u-text-justify'>
                    <h4>ESTIMADO USUARIO</h4>
                </div>
                <p className='u-pb-small'>
                    {payload &&
                        privacyPoliciesLabels.initialText.replace(
                            '[requestId]',
                            payload.requestId,
                        )}
                </p>
                {/* <BronsonAccordion config={{ rows }} /> */}
                {rows && rows.length && <BronsonAccordion config={{ rows }} />}
            </div>
            <div className='o-layout u-mv'>
                <div className='o-layout__item u-4/5'>
                    <b>Importante:</b> {privacyPoliciesLabels.note}
                </div>
                <div className='o-layout__item u-1/5'>
                    {signed ? (
                        <span
                            style={{ background: '#05ce9f' }}
                            className='c-btn u-float-right u-text-white'
                        >
                            {privacyPoliciesLabels.button.signed}
                        </span>
                    ) : (
                        <BronsonButton
                            key={'accept-button'}
                            label={buttonText}
                            click={() => signPrivacyPolicies()}
                            dataComponent={'accept-button'}
                            config={{
                                types: [],
                                isDisable: !enableAcceptButton,
                                elementClassModifier:
                                    'u-float-right u-text-center',
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicies;
