import Keycloak from 'keycloak-js';
import { ApiGatewayClient, HttpMethod } from '@dh/keycloak-auth';

import { apiGatewayClientConfig, keycloakConfig } from './config';
import { SuccessResponse } from './utils/interfaces';

/**
 * Class responsible for manage the API calls and data request of the App
 */
export class AppService {
    protected client: ApiGatewayClient;
    private readonly controllerPathRealm = `/realm/${keycloakConfig.realm}`;

    public readonly postHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    /**
     * Instantiates a new ApiGatewayClient
     */
    constructor(keycloak: Keycloak.KeycloakInstance) {
        apiGatewayClientConfig.authorization = keycloak.idToken;
        this.client = new ApiGatewayClient(apiGatewayClientConfig);
    }

    /**
     * Method to set attributes to the user.
     * @returns {Promise<SuccessResponse>}
     */
    public async setAttribute(id: string): Promise<SuccessResponse> {
        return this.client
            .fetch<SuccessResponse>({
                path: `${this.controllerPathRealm}/add-attribute/user/${id}`,
                method: HttpMethod.PUT,
                body: {
                    attributes: {
                        group: ['vwfs'],
                    },
                },
            })
            .then((response) => response.data);
    }

    public handleError = (error) => {
        let message = error?.response?.data?.message || error.toString();
        if (Array.isArray(message)) {
            const constraints = message[0].constraints;
            if (constraints) message = JSON.stringify(constraints);
        }
        return message;
    };
}
